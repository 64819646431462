import { IconButton, Fab } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

type BackButtonProps = {
    className?: string;
    onClick: () => void;
};

const CloseButton: React.FC<BackButtonProps> = ({ className, onClick }) => (
    <IconButton
        className={ className }
        color="inherit"
        aria-label="close"
        edge="end"
        onClick={ onClick }
    >
        <CloseIcon />
    </IconButton>
);

export default CloseButton;
