import { Paper, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import BackButton from '../Components/Common/BackButton';

import ErrorModal from '../Components/Common/ErrorModal';
import { Logo } from '../Components/Logo';
import SignIn from '../domain/login/components/Login/SignIn';
import SignInForgotPassword from '../domain/login/components/Login/SignInForgotPassword';
import { authErrorMessages } from '../domain/login/messages/authErrorMessages';
import { useRootNavigation } from '../Hooks/useRootNavigation';
import { useStores } from '../Stores/useStores';

type SignInPageProps = {
};

const SignInPage: React.FC<SignInPageProps> = (props) => {
    const history = useHistory();
    const { layoutStore, signInStore } = useStores();
    const match = useRouteMatch();
    const classes = useStyles();

    useEffect(() => {
        signInStore.reset();
    }, []);

    const handleBack = () => {
        history.goBack();
    };

    return (
        <>
            { layoutStore.isMobile
                ? renderInner()
                : (
                    <Paper elevation={ 3 } className={ classes.desktopContainer }>{ renderInner() }</Paper>
                ) }

            <ErrorModal
                open={ !!signInStore.errorToDisplay }
                errorMessage={ getErrorMessage() }
                onClose={ () => signInStore.clearErrorToDisplay() }
            />
        </>
    );

    function renderInner() {
        return (
            <>
                <div className={ classes.header }>
                    <div>
                        <BackButton onClick={handleBack} edge={false} />
                    </div>
                    <div className={ classes.logoContainer }>
                        <Logo height={ 50 } />
                    </div>
                </div>
                <Switch>
                    <Route path={ match.url } exact component={ SignIn } />
                    <Route path={ `${match.url}/resetPassword` } exact component={ SignInForgotPassword } />
                </Switch>
            </>
        );
    }

    function getErrorMessage() {
        if (!signInStore.errorToDisplay) {
            return;
        }
        return (authErrorMessages as any)[signInStore.errorToDisplay] ?? authErrorMessages.unknown;
    }
};

export default observer(SignInPage);

const useStyles = makeStyles((theme: Theme) => createStyles({
    desktopContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: theme.spacing(8),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 500,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
    },
    logoContainer: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 48,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
}));
