import { MapSearchLevel } from './Types';

export const ZOOM_LEVELS_MAP = new Map<MapSearchLevel, number>([
    ['country', 0],
    ['kraj', 6],
    ['okres', 8],
    ['cities', 12],
    ['places', 14],
]);

export const ZOOM_LEVELS_MAP_CATEGORY = new Map<MapSearchLevel, number>([
    ['country', 0],
    ['kraj', 6],
    ['places', 10],
]);
