import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import CategoryMenuItem from '../../../../shared/src/Core/Models/CategoryMenuItem';
import CategoryMenuService from '../../../../shared/src/Core/Services/CategoryMenuService';
import { useService } from '../../Hooks/useService';
import { useStores } from '../../Stores/useStores';

import CategoryMenuItemComponent from './CategoryMenuItemComponent';
import CategoryMenuItemMobilComponent from './CategoryMenuItemMobilComponent';
import { useHomeNavigation } from '../../Hooks/useHomeNavigation';
import { Button } from '@material-ui/core';
import LayoutStore from '../../Stores/LayoutStore';
import CategoryMenuItemSmallComponent from './CategoryMenuItemSmallComponent';

type CategoryMenuProps = {
};

const CategoryMenu: React.FC<CategoryMenuProps> = () => {
    const { layoutStore } = useStores();
    const classes = useStyles();
    const categoryMenuSvc = useService(CategoryMenuService);
    const [menuItems, setMenuItems] = useState<CategoryMenuItem[]>([]);
    const { navigateToCategoryBrowse } = useHomeNavigation();

    const handleClickBrowseAll = () => {
        navigateToCategoryBrowse('all');
    };

    useEffect(() => {
        loadCategories();
    }, []);

    return (
        <div className={ classes.root }>
            {
                layoutStore.isMobile ?
                <>
                {
                (menuItems.slice(0, 5).map((item) => (
                    <CategoryMenuItemMobilComponent key={ item.key } item={ item } />
                )))
                }
                <br />
                {
                (menuItems.slice(5, 10).map((item) => (
                    <CategoryMenuItemMobilComponent key={ item.key } item={ item } />
                )))
                }
                </>
                :
                (   LayoutStore.getWindowSize() < 1700 ?
                    (menuItems.map((item) => (
                        <CategoryMenuItemSmallComponent key={ item.key } item={ item } />
                    )))
                    :
                    (menuItems.map((item) => (
                        <CategoryMenuItemComponent key={ item.key } item={ item } />
                    )))
                )
            }
            {!layoutStore.isMobile && 
                <Button
                onClick={ handleClickBrowseAll }
                className={ classes.sipka }
                variant="contained"
                disableElevation
            >
<svg viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">

  <rect x="30" y="30" width="80" height="80" stroke="green" fill="none" stroke-width="3"/>
  <rect x="140" y="30" width="80" height="80" stroke="green" fill="none" stroke-width="3"/>

  <rect x="30" y="140" width="80" height="80" stroke="green" fill="none" stroke-width="3"/>
  <rect x="140" y="140" width="80" height="80" stroke="green" fill="none" stroke-width="3"/>
</svg>
                </Button>
            }
        </div>
    );

    async function loadCategories() {
        const items = await categoryMenuSvc.getMenuItems();
        setMenuItems(items);
    }
};

export default observer(CategoryMenu);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        pointerEvents: 'all',
        backgroundColor: 'white',
        // padding: theme.spacing(0, 0.5),
    },
    sipka: (params: any) => ({
        color: 'rgba(120, 190, 32, 1)',
        width: theme.spacing(0),
        paddingTop: theme.spacing(0),
        cursor: 'pointer',
        paddingBottom: theme.spacing(0),
        backgroundColor: params.isSelected
            ? theme.palette.primary.light
            : 'transparent',
    }),
}));
