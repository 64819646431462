import { Button, Link as MaterialLink } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { useHomeNavigation } from '../../Hooks/useHomeNavigation';
import { useStores } from '../../Stores/useStores';

const MainSearchButton: React.FC = observer(() => {
    const { appStore } = useStores();
    const classes = useStyles();
    const { navigateToCategoryBrowse, getLinkTo } = useHomeNavigation();

    const onClick = () => {
        navigateToCategoryBrowse('all');
    };

    return (
        <div className={ classes.container }>
            <Button autoFocus variant="contained" color="primary" aria-label="search" className={ classes.root } onClick={ onClick }>
                <SearchIcon className={ classes.searchButtonIcon } />
                <FormattedMessage
                    id="BUTTON_CTA_SEARCH_CATEGORIES"
                    defaultMessage="Zjistit kam s ním"
                />
            </Button>
            { /* { appStore.allowCovid && (
                <div className={ classes.linkContainer }>
                    <Link to={ getLinkTo('/categories/covid') }>
                        <MaterialLink>Kam na odběr COVID-19?</MaterialLink>
                    </Link>
                </div>
            ) } */ }
        </div>
    );
});

export default MainSearchButton;

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        textAlign: 'center',
    },
    linkContainer: {
        marginTop: theme.spacing(2),
        fontSize: 16,
        textDecoration: 'underline',
    },
    root: {
        // flexGrow: 1,
        borderRadius: 48,
        padding: theme.spacing(2, 4, 2, 3),
    },
    searchButtonIcon: {
        marginRight: theme.spacing(2),
    },
}));
