import { Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { IEnvironment_TYPE } from '../../../../shared/src/Core/IEnvironment';
import IocContainer from '../../../../shared/src/Core/IocContainer';
import Environment from '../../environment';

type BetaBadgeProps = {
    small?: boolean
};

const BetaBadge: React.FC<BetaBadgeProps> = (props) => {
    const classes = useStyles({ small: props.small });

    const env = IocContainer.get<Environment>(IEnvironment_TYPE);
    if (!env.isBeta) {
        return null;
    }

    return (
        <div className={ classes.root }>
            <div className={ classes.ribbon }>BETA</div>
        </div>
    );
};

export default BetaBadge;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: (params: any) => ({
        pointerEvents: 'none',
        position: 'absolute',
        zIndex: 500,
        overflow: 'hidden',
        top: 0,
        right: 0,
        height: 64,
        width: 64,
        transform: params.small ? 'scale(.8) translateX(10%) translateY(-10%)' : undefined,
        opacity: 0.8,
    }),
    ribbon: {
        position: 'absolute',
        userSelect: 'none',
        top: theme.spacing(1.4),
        right: theme.spacing(-3),
        fontSize: 12,
        letterSpacing: 2,
        // color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        padding: theme.spacing(0.25, 3),
        transform: 'rotateZ(45deg)',
    },
}));
