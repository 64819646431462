import { Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { PlaceInfo } from '../../../../shared/src/Core/Models/PlaceInfo';
import { useStores } from '../../Stores/useStores';
import ResponsiveModal from '../Layout/ResponsiveModal';

import PlaceFeedbackView from './PlaceFeedbackView';

const FeedbackMessages = defineMessages({
    title: {
        id: 'PLACE_FEEDBACK_TITLE',
        defaultMessage: 'Hlášení chybných údajů',
    },
});

type PlaceFeedbackModalProps = {
    visible: boolean;
    place: PlaceInfo;
    onClose: () => void;
};

const PlaceFeedbackModal: React.FC<PlaceFeedbackModalProps> = ({
    visible,
    place,
    onClose,
}) => {
    const intl = useIntl();
    const { layoutStore } = useStores();
    const classes = useStyles({ isMobile: layoutStore.isMobile });

    return (
        <ResponsiveModal open={ visible } title={ intl.formatMessage(FeedbackMessages.title) } onClose={ onClose }>
            <div className={ classes.inner }>
                <PlaceFeedbackView place={ place } onSubmit={ onClose } />
            </div>
        </ResponsiveModal>
    );
};

export default observer(PlaceFeedbackModal);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: { },
    inner: (params: any) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        marginBottom: params.isMobile ? '20vh' : undefined,
    }),
}));
