import { Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React from 'react';
import { clsx } from '../../../utils/WebHelpers';

type AccountIconProps = {
    className?: string;
};

const AccountIcon: React.FC<AccountIconProps> = ({ className}) => {
    const classes = useStyles();

    return (
        <AccountCircleIcon className={ clsx(classes.root, className ?? '') } width={ 40 } height={40} />
    );
};

export default AccountIcon;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        flex: 1,
        width: 40,
        height: 40,
        color: 'lightGray',
    },
}));
