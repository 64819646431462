import {
    Typography, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { PlaceInfo } from '@shared/Core/Models/PlaceInfo';
import { formatDistance } from '@shared/Core/Utils/Helpers';
import React from 'react';

import { clsx } from '../../utils/WebHelpers';
import OpenIndicator from '../Common/OpenIndicator';

import PlaceIcon from './PlaceIcon';
import ContainerIco from '../Icons/ContainerIco';
import HandIco from '../Icons/HandIco';
import BoxIco from '../Icons/BoxIco';

type PlaceItemProps = {
    place: PlaceInfo;
    onItemClick: (item: PlaceInfo) => void;
};

const PlaceItem: React.FC<PlaceItemProps> = (props) => {
    const { place } = props;

    const classes = useStyles({
        nadobaColor: place.nadoba?.color,
    });

    const onItemClick = () => {
        props.onItemClick(place);
    };
	console.log(place);

    return (
        <ListItem key={ place.id } button onClick={ onItemClick } className={ classes.root }>
            <ListItemIcon className={ classes.listItemIcon }>
                <PlaceIcon placeType={ place.type } size="42px" />
            </ListItemIcon>
            <ListItemText
                className={ classes.itemContent }
                primary={ <Typography component="h2" className={ classes.title }>{ place.name }</Typography> }
                secondary={ (
                    <Typography component="div">
                        <Typography component="div" variant="body2" noWrap className={ classes.subTitle }>{ place.address }</Typography>
                        <Typography component="div" variant="body2" className={ classes.info }>
                            <div className={ classes.infoItem }>
                                <OpenIndicator mode="normal" type={ place.open.type } />
                            </div>

                            { /* <span>&nbsp;|&nbsp;</span> */ }

                            { place.distance && (
                                <div className={ classes.infoItem }>
                                    { formatDistance(place.distance) }
                                </div>
                            ) }

							{ place.nadoba &&
								['containerPoint1','containerPoint2','containerPoint3', 'containerPointH',
								'container1', 'container2', 'container3', 'track1', 'track2', 'track3'].includes(place.type) &&
							(
                                <>
									<span className={classes.iconSpan} title={place.nadoba.nazev}><ContainerIco color={place.nadoba.color} size={32}></ContainerIco></span>
                                    <span className={ clsx(classes.nadobaText, classes.infoItem) }>{ place.nadoba.nazev }</span>
                                    { /* <span>&nbsp;|&nbsp;</span> */ }
                                </>
                            ) }

							{	!place.nadoba && place.nadoby &&
								['containerPoint1','containerPoint2','containerPoint3', 'containerPointH',
									'container1', 'container2', 'container3', 'track1', 'track2', 'track3'].includes(place.type) &&
									<div className={ classes.nadoby }>{place.nadoby.map((item, key) => <span key={key} title={item.nazev}><ContainerIco color={item.color} size={32}></ContainerIco></span>)}</div>
							}

							{
								['reuse3','charity3'].includes(place.type) &&
									<>
										<span className={classes.iconSpan} title={place.name}><HandIco color={''} size={28}></HandIco></span>
										<span className={ clsx(classes.nadobaText, classes.infoItem) }>{ 'Osobní předání' }</span>
										{ /* <span>&nbsp;|&nbsp;</span> */ }
									</>
							}

{
								(place.name.startsWith('Balíkovna') || ['box3'].includes(place.type)) &&
									<>
										<span className={classes.iconSpan} title={place.name}><BoxIco color={''} size={28}></BoxIco></span>
										<span className={ clsx(classes.nadobaText, classes.infoItem) }>{ 'Zaslat balíkem' }</span>
										{ /* <span>&nbsp;|&nbsp;</span> */ }
									</>
							}
                        </Typography>
                    </Typography>
                ) }
            />
        </ListItem>
    );
};

export default PlaceItem;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(1, 2, 1, 1),
        alignItems: 'flex-start',
    },
    listItemIcon: {
        marginTop: theme.spacing(1),
        justifyContent: 'center',
    },
    itemContent: {
        paddingLeft: theme.spacing(2),
    },
	nadoby: {
		paddingTop: '3px',
		height: '20xp',
		// position: 'relative',
		// top: '-4px',
	},
    nadoba: (params: any) => ({
        backgroundColor: `#${params.nadobaColor}`,
        display: 'inline-block',
        width: '8px',
        height: '8px',
        border: '1px solid gray',
        marginRight: '.4rem',
    }),
    nadobaText: (params: any) => ({
        // color: theme.palette.getContrastText(`#${params.nadobaColor}`),
        // backgroundColor: `#${params.nadobaColor}`,
        // padding: theme.spacing(0.25, 1, 0.25, 1),
        // borderRadius: 6,
        // border: '1px solid whitesmoke',

    }),
	iconSpan: {
		paddingTop: '4px',
	},
    title: {
        lineHeight: 1.4,
    },
    subTitle: {
        fontWeight: 'lighter',
    },
    info: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 0),
        fontWeight: 'lighter',
		height: '24px',
    },
    infoItem: {
        marginRight: theme.spacing(1),
		fontSize: '.7rem',
		lineHeight:  '.7rem',
		[theme.breakpoints.up('sm')]: {
            fontSize: '.9rem',
			lineHeight:  '.9rem',
        },
    },
}));
