import { observer } from 'mobx-react-lite';
import React, { } from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, useIntl } from 'react-intl';
import { Switch, Route } from 'react-router';

import { PlaceInfo } from '../../../shared/src/Core/Models/PlaceInfo';
import { useStores } from '../Stores/useStores';



type AppHelmetProps = {
    subtitle?: string;
    rootPath: string;
};

const AppHelmetMessages = defineMessages({
    pageTitleDefault: {
        id: 'PAGE_TITLE_DEFAULT',
        defaultMessage: 'Aplikace Kam s ním? - třiďte odpady efektivně',
    },
    pageTitlePartners: {
        id: 'PAGE_TITLE_PARTNERS',
        defaultMessage: 'Partneři',
    },
    pageTitleContact: {
        id: 'PAGE_TITLE_CONTACT',
        defaultMessage: 'Kontakt',
    },
    pageMetaDescription: {
        id: 'PAGE_META_DESCRIPTION',
        defaultMessage: 'Kompletní průvodce tříděním odpadů. Najděte kontejnery, sběrné dvory, re-use centra, swapy a další místa ve vašem okolí. Chovejme se ekologicky a pomozte nám předcházet vzniku odpadů, být šetrnější k životnímu prostředí a snižovat naši uhlíkovou stopu.',
    },
});

const HelmetBase: React.FC<{
    title?: string,
    subtitle?: string,
    description?: string,
    keywords?: string[],
    noIndex?: boolean,
}> = observer(({
    title, subtitle, description, keywords, noIndex, children,
}) => {
    const { localeStore, appStore } = useStores();
    const intl = useIntl();
    //const canonicalUrl = window.location.href.substr(0, window.location.href.length - window.location.search.length);
	const canonicalUrl = 'https://www.kamsnim.cz' + window.location.pathname;

    const titleText = getTitle();
    let descriptionText = intl.formatMessage(AppHelmetMessages.pageMetaDescription);
    if (description) {
        descriptionText = description;
    }
    return (
        <Helmet
            title={ titleText }
            htmlAttributes={ { lang: localeStore.locale } }
        >
            <meta property="og:title" content={ titleText } />
            <meta property="og:description" content={ descriptionText } />

			{ !appStore.isApp && <meta name="apple-itunes-app" content="app-id=1494784776" />}
			{ false && !appStore.isApp && <meta name="google-play-app" content="app-id=cz.kamsnim.app" />}
			{ !appStore.isApp && <link rel="apple-touch-icon" href="logo512.png" />}
			{ false && !appStore.isApp && <link rel="android-touch-icon" href="logo512.png" />}


			<meta property="og:type" content="website" />
			<meta property="og:locale"  content="cs_CZ" />

			<meta property="og:image"  content="https://www.kamsnim.cz/images/kamsnim002.jpg" />
			<meta property="og:image:type" content="image/jpeg" />
			<meta property="og:image:width" content="1200" />
			<meta property="og:image:height" content="1200" />
			<meta property="og:image:alt" content="Kam s ním? - snadné vyhledání míst, kde se můžete legálně zbavit odpadu." />

			<meta property="fb:app_id"             content="1416849408455617" />

            <meta name="description" content={ descriptionText } />
            <link rel="canonical" href={ canonicalUrl } />

            { keywords && keywords.length > 0 && (
                <meta name="keywords" content={ keywords?.join(',') } />
            ) }
            { noIndex && (
                <meta name="robots" content="noindex" />
            ) }
            { children }
        </Helmet>
    );

    function getTitle() {
        if (title) {
            return title;
        }

        const defaultTitle = intl.formatMessage(AppHelmetMessages.pageTitleDefault);
        return subtitle
            ? `${subtitle} - Kam s ním?` // ${defaultTitle}
            : defaultTitle;
    }
});

const AppHelmet: React.FC<AppHelmetProps> = ({ rootPath }) => {
    const { categoryStore, searchStore, homeStore } = useStores();
    const intl = useIntl();

    return (
        <Switch>
            <Route path={ `${rootPath}/partners` }>
                <HelmetBase
                    subtitle={ intl.formatMessage(AppHelmetMessages.pageTitlePartners) }
                />
            </Route>
            <Route path={ `${rootPath}/contact` }>
                <HelmetBase
                    subtitle={ intl.formatMessage(AppHelmetMessages.pageTitleContact) }
                />
            </Route>
            <Route path={ `${rootPath}/places/:placeId` }>{ renderSelectedPlace() }</Route>
            <Route path={ `${rootPath}/browse/:categoryId` }>
                <HelmetBase />
            </Route>
            <Route path={ `${rootPath}/categories/:categoryId` }>
                <Route path={ `${rootPath}/categories/:categoryId` } exact>{ renderSelectedCategory() }</Route>
                <Route path={ `${rootPath}/categories/:categoryId/places/:placeId` }>{ renderSelectedPlace() }</Route>
                <Route path={ `${rootPath}/categories/:categoryId/products/:productId` }>
                    <Route path={ `${rootPath}/categories/:categoryId/products/:productId` } exact>{ renderSelectedCategory() }</Route>
                    <Route path={ `${rootPath}/categories/:categoryId/products/:productId/places/:placeId` }>{ renderSelectedPlace() }</Route>
                </Route>
            </Route>
            <Route>
                <HelmetBase />
            </Route>
        </Switch>
    );

    function renderSelectedCategory() {
        return (
            <HelmetBase
                title={ categoryStore.selectedCategory?.seoTitle }
                subtitle={ categoryStore.selectedTitle }
                keywords={ [categoryStore.selectedTitle, ...categoryStore.keywords] }
                description={ categoryStore.selectedCategory?.seoDescription?.length
                    ? categoryStore.selectedCategory.seoDescription
                    : undefined }
            />
        );
    }

    function renderSelectedPlace() {
        const { place } = homeStore;
        if (!place) {
            return <HelmetBase />;
        }

        const subtitle = getPlaceSubtitle(place);
        const description = getPlaceDescription(place);

        return (
            <HelmetBase
                subtitle={ subtitle }
                keywords={ homeStore.place?.category }
                description={ description }
            />
        );
    }

    function getPlaceSubtitle(place: PlaceInfo) {
        const subtitleParts = [place.name];
        if (place.nadoby.length === 1) {
            subtitleParts.push(place.nadoby[0].nazev);
        }
        subtitleParts.push(place.address);

        return subtitleParts.join(' - ');
    }

    function getPlaceDescription(place: PlaceInfo) {
        const parts = [
            place.name,
            ...place.nadoby.map((nadoba) => nadoba.nazev),
        ];

        return parts.join(' - ');
    }
};

export default observer(AppHelmet);
